<script lang="ts" setup>
</script>

<template>
  <div>
    <slot name="content" />
  </div>
</template>

<style lang="scss" scoped></style>
